// BlogsContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader } from "../apis/header";

const BlogsContext = createContext();

export const BlogsContextProvider = ({ children }) => {
  const [allBlogs, setAllBlogs] = useState(null);

  const getBlogs = async (token) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", "/api/v1/get/posts", {}, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setAllBlogs(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  const addBlog = async (token, blogData) => {
    try {
      // Implement the logic to add a blog using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const deleteBlog = async (token, blogId) => {
    try {
      // Implement the logic to delete a blog using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const updateBlog = async (token, blogId, blogData) => {
    try {
      // Implement the logic to update a blog using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <BlogsContext.Provider
      value={{
        allBlogs,
        getBlogs,
        addBlog,
        deleteBlog,
        updateBlog,
      }}
    >
      {children}
    </BlogsContext.Provider>
  );
};

export const useBlogsContext = () => {
  const blogsContextValue = useContext(BlogsContext);
  return blogsContextValue;
};
