import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from "./contexts/AuthContextProvider";
import { CategoriesContextProvider } from "./contexts/CategoriesContextProvider";
import { LocationContextProvider } from "./contexts/LocationContextProvider";
import { CoordinatesContextProvider } from "./contexts/CoordinatesContextProvider";
import { EventContextProvider } from "./contexts/EventContextProvider";
import { IconsContextProvider } from "./contexts/IconsContextProvider";
import { BlogsContextProvider } from './contexts/BlogsContextProvider';
import { ContactSupportContextProvider } from './contexts/ContactSupportContextProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <CategoriesContextProvider>
          <LocationContextProvider>
            <CoordinatesContextProvider>
              <EventContextProvider>
                <IconsContextProvider>
                  <BlogsContextProvider>
                    <ContactSupportContextProvider>
                    <App />
                    </ContactSupportContextProvider>
                  </BlogsContextProvider>
                </IconsContextProvider>
              </EventContextProvider>
            </CoordinatesContextProvider>
          </LocationContextProvider>
        </CategoriesContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
