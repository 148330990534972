import React from "react";
import Banner from "../../components/banner";

import aboutus1 from "../../images/aboutus1.webp";
import aboutus2 from "../../images/aboutus2.webp";
import aboutus3 from "../../images/aboutus3.webp";
import aboutus4 from "../../images/aboutus4.webp";
import aboutus5 from "../../images/aboutus5.webp";
import aboutus6 from "../../images/aboutus6.webp";

function AboutUs() {
  return (
    <div>
      <Banner
        image={require("../../images/about_banner-bg.png")}
        title="About Us"
      />

      <div className="md:w-[70%] w-full md:p-0 px-4 mt-16 mx-auto flex flex-col gap-20">
        <AboutUsCard
          showImageFirst={false} // Set to false if you want to show content first
          description2="Welcome to a place where tranquility meets transformation. Our yoga retreats are designed to take you on a journey of self-discovery and renewal. Join us amidst breathtaking natural settings, immerse yourself in the practice of yoga, and reconnect with your inner peace. Explore our retreats and embark on a path to serenity, one asana at a time."
          description="At Your Yoga Retreat, we are passionate advocates of holistic well-being and mindful living. Our journey began with a shared vision of creating a sanctuary where individuals from diverse backgrounds could embark on a transformative path to wellness."
          imageUrl={aboutus1}
        />
        <AboutUsCard
          showImageFirst={true} // Set to false if you want to show content first
          title="Our Mission"
          description="At Your Yoga Retreat, we are passionate advocates of holistic well-being and mindful living. Our journey began with a shared vision of creating a sanctuary where individuals from diverse backgrounds could embark on a transformative path to wellness.
Promoting not just physical wellness but also mental and spiritual growth. Whether you are new to yoga or an experienced practitioner, engaging with these philosophies can enrich your practice and everyday life. Aiming to foster holistic well-being by embracing Yoga, emphasizing mindfulness, connecting with nature, building a supportive community, and promoting self-discovery
."
          imageUrl={aboutus2}
        />
        <AboutUsCard
          showImageFirst={false} // Set to false if you want to show content first
          title="Our Philosophy"
          description="At Your Yoga Retreat, we are passionate advocates of holistic well-being and mindful living. Our journey began with a shared vision of creating a sanctuary where individuals from diverse backgrounds could embark on a transformative path to wellness.
Promoting not just physical wellness but also mental and spiritual growth. Whether you are new to yoga or an experienced practitioner, engaging with these philosophies can enrich your practice and everyday life. Aiming to foster holistic well-being by embracing Yoga, emphasizing mindfulness, connecting with nature, building a supportive community, and promoting self-discovery
."
          imageUrl={aboutus3}
        />
        <AboutUsCard
          showImageFirst={true} // Set to false if you want to show content first
          title="Expert Instructor"
          description="Our experts are more than just a skilled practitioner; they embody the essence of yoga through their knowledge, communication skills, compassion, patience, and authenticity. They are mindful and present, adaptable to the needs of their students, and maintain a high level of professionalism. By integrating the philosophical and spiritual aspects of yoga into their teaching and living its principles in their daily lives, they inspire and guide their students towards holistic well-being. These qualities enable them to create a supportive and enriching environment, helping individuals achieve physical, mental, and spiritual growth.

."
          imageUrl={aboutus4}
        />
        <AboutUsCard
          showImageFirst={false} // Set to false if you want to show content first
          title="Sustainable Practice"
          description="Implementing sustainable practices in a yoga retreat helps create a harmonious balance between personal well-being and environmental health. By focusing on eco-friendly accommodations, sustainable food practices, water and waste management, eco-friendly products, mindful transportation, educational initiatives, and fostering a deep connection to nature, retreats can significantly reduce their environmental impact. These practices not only enhance the retreat experience but also inspire participants to adopt more sustainable lifestyles, extending the benefits beyond the duration of the retreat.."
          imageUrl={aboutus5}
        />
        <AboutUsCard
          showImageFirst={true} // Set to false if you want to show content first
          title="Your Journey with Us"
          description="
Your journey with us at the yoga retreat is designed to be a transformative experience, nurturing your body, mind, and spirit. Through a combination of yoga and meditation practices, sustainable living education, connection with nature, and personal growth opportunities, you’ll leave feeling rejuvenated, inspired, and equipped with tools to continue your path towards holistic well-being. We look forward to sharing this enriching journey with you and supporting you every step of the way.


."
          imageUrl={aboutus6}
        />
      </div>
      {/* bottom dropdown */}
      <div>
        <div className="h-[19rem] mt-20 relative">
          <img
            loading="lazy"
            className="h-[19rem] w-full  object-cover object-no-repeat object-center"
            src={require("../../images/place_pic_1.png")}
            alt="Terms and conditions"
          />
          <div className="w-[100%] top-0 left-0 absolute h-[19rem] bg-black bg-opacity-70 flex justify-center items-center">
            <div className="inset-0 flex flex-col items-center justify-center text-white ">
              <h1 className="text-2xl font-bold text-center text-white">
                Join Our Community
              </h1>
              <div className="text-sm font-normal text-center md:w-[60%] w-full mt-6 mb-5">
                <p className="mb-1">
                  We invite you to become a part of our growing community of
                  like-minded individuals who believe in the power of yoga to
                  transform lives. Together, we strive to cultivate mindfulness,
                  wellness, and joy in every moment.
                </p>
                <p>
                  Thank you for considering us as your partner on this
                  transformative journey. We look forward to welcoming you to
                  our retreats and sharing the path to a more balanced and
                  blissful life.
                </p>
              </div>
              {/* Download Button */}
              <div className="items-center hidden lg:flex ms-4">
                <button className=" cursor-pointer px-14 py-2 font-normal text-white bg-[#8DC67B] rounded-md  hover:text-white gradient-bg">
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;

const AboutUsCard = ({
  showImageFirst,
  title = null,
  description,
  description2 = null,
  imageUrl,
}) => {
  const content = (
    <div className="flex flex-col items-start justify-center h-full gap-4">
      {title ? (
        <h2 className="mb-2 text-2xl font-bold">{title}</h2>
      ) : (
        <p className="mb-3 text-sm">{description2}</p>
      )}
      <p className="text-sm">{description}</p>
    </div>
  );

  const imageSection = (
    <div className="flex items-center">
      <img
        loading="lazy"
        src={imageUrl}
        alt="Place"
        className="object-cover bg-black  object-center w-full rounded-lg h-[14rem]"
      />
    </div>
  );

  return (
    <div className={`flex  gap-10 md:flex-row flex-col w-full `}>
      <div className={`flex-1 `}>{showImageFirst ? imageSection : content}</div>
      <div className={`flex-1  `}>
        {showImageFirst ? content : imageSection}
      </div>
    </div>
  );
};
