import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEventContext } from "../../contexts/EventContextProvider";
import { baseUrl } from "../../apis/base_url";

function AboutUs() {
  return (
    <div>
      <div className="md:w-[60%] w-full md:p-0 px-4 my-16 mx-auto">
        <h1 className="text-[#2C2C2C] md:text-5xl text-3xl font-extrabold text-center">
          About Us
        </h1>
        <h3 className="text-[#505D68] md:text-2xl text-xl font-semibold text-center">
          Discover Your Inner Peacer
        </h3>
        <div className="flex md:flex-row flex-col gap-10 mt-7 ">
          <div className="md:w-[40%] w-[100%]">
            <img
              loading="lazy"
              className="rounded-md w-full h-[18rem] "
              src={require("../../images/place_pic_1.png")}
              alt="place"
            />
          </div>
          <div className="md:w-[50%] w-[100%] text-[#505D68] text-sm flex flex-col gap-4">
            <p>
              we are passionate advocates of holistic well-being and mindful
              living. Our journey began with a shared vision of creating a
              sanctuary where individuals from diverse backgrounds could embark
              on a transformative path to wellness.
            </p>
            <p>
              we are passionate advocates of holistic well-being and mindful
              living. Our journey began with a shared vision of creating a
              sanctuary where individuals from diverse backgrounds could embark
              on a transformative path to wellness.
            </p>
            <p>
              we are passionate advocates of holistic well-being and mindful
              living. Our journey began with a shared vision of creating a
              sanctuary where individuals from diverse backgrounds could embark
              on a transformative path to wellness.
            </p>
          </div>
        </div>
        <h3 className="text-[#505D68] md:text-2xl text-xl font-semibold text-center mt-14 ">
          Capturing Moments of Inner Peace and Serenity
        </h3>
      </div>
      <div className="w-[100%] overflow-x-hidden mb-5">
        <AboutUsSlider />
      </div>
    </div>
  );
}

export default AboutUs;

function AboutUsSlider() {
  const { allEvents } = useEventContext();
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3.7,
    slidesToScroll: 1,
    aautoplay: true, // Enable auto-scrolling
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2.7,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.7
        }
      },
      //   {
      //     breakpoint: 480,
      //     settings: {
      //       slidesToShow: 1
      //     }
      //   }
    ]
  };
  return (
    <Slider {...settings}>
      {
        allEvents && allEvents["app_media"].map((item, index) => {
          return <div key={index} className="md:px-5 px-2">
            <img
              loading="lazy"
              className="rounded-md w-full h-[18rem] object-cover object-center "
              src={baseUrl+"/"+item.path+"/"+item.name}
              alt="place"
            />
          </div>
        })
      }

     

    </Slider>
  );
}
