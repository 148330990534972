

import { useParams } from "react-router-dom";


import Banner from "../../components/banner";
import { useEffect, useState } from "react";
import { useEventContext } from "../../contexts/EventContextProvider";
import { baseUrl } from "../../apis/base_url";
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
 
const BlogById = () => {

  const { id } = useParams();
  const [currentBlog, setCurrentBlog] = useState(null)
  const { allEvents, getEvents } = useEventContext();
  const [loading, setLoading] = useState(true)

  const fetchCurrentBlog = async () => {
    setLoading(true);
    allEvents && allEvents["blogs"] && setCurrentBlog(allEvents["blogs"].find(item => item.id == id))
    setLoading(false);
  }

  const getAllEvents = async () => {
    setLoading(true);
    await getEvents();
    setLoading(false);
  }

  useEffect(() => {

    getAllEvents();
  }, []);

  useEffect(() => {
    fetchCurrentBlog();
  }, [id]);

  useEffect(() => {
    fetchCurrentBlog();
  }, [allEvents]);

  return (
    <section>
      {currentBlog ? <div>
        {/* banner */}
        <Banner image={baseUrl + "/" + currentBlog.image.path + "/" + currentBlog.image.name} title={currentBlog.title} />
        {/* text  */}
        <div className="md:w-[70%] w-full md:p-0 px-4 mt-16 mx-auto">



          <div className=" flex gap-5 mt-5 items-center">
            <h1
              className="rounded-full w-[2.5rem] h-[2.5rem] bg-black text-white font-semibold text-center text-3xl pt-1"

            >{currentBlog?.user?.name[0]}</h1>

            <div>
              <p className="opacity-70 text-sm">{currentBlog?.user?.name}</p>
              <p className="opacity-70 text-sm">{currentBlog?.posted_at}</p>
            </div>
          </div>

           

          <div className="mt-4 flex flex-col gap-2 blogContent" dangerouslySetInnerHTML={{ __html: currentBlog.content }} />
          {/* <section>
            {ReactHtmlParser(currentBlog?.content)}
          </section> */}

        </div>
      </div> :
        loading ? <h1 className="my-10 text-base text-center w-full font-bold">Loading ...</h1> : <h1 className="my-10 text-base text-center w-full font-bold">No blog found.</h1>

      }
    </section>
  );
};

export default BlogById;
