import { useEffect, useRef, useState } from "react";
import Banner from "../../components/banner";
import { BsCamera } from "react-icons/bs";
import userLogo from "../../images/User.svg";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { useNavigate } from "react-router-dom"

const ProfilePage = () => {
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);
    const { userProfile, checkUser, isLoggedIn, isUserLoggedIn } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        profileImage: null,

        name: '',

        address: '',

        email: '',

        phoneNumber: '',

    });

    const setUserProfileData = async () => {
        userProfile ? setFormData({
            // profileImage: userProfile.,

            name: userProfile.name ? userProfile.name : null,

            address: userProfile.address ? userProfile.address : "",

            email: userProfile.email ? userProfile.email : "",

            phoneNumber: userProfile.phone ? userProfile.phone : "",
        }) : setFormData({
            // profileImage: userProfile.,

            name: "",

            address: "",

            email: "",

            phoneNumber: "",
        })

    }

    const fetchUserProfileData = async () => {

        setLoading(true)
        await checkUser();
        setLoading(false)

    }

    const checkUserStatus = async () => {
        if (!isLoggedIn && !loading) {
            navigate("/login");
        }
    }



    useEffect(() => {
        setUserProfileData();
    }, [userProfile])

    useEffect(() => {
        fetchUserProfileData();

    }, [])




    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageUploadClick = () => {
        fileInputRef.current.click();
    };

    if(!isLoggedIn){
        return navigate("/login");
    }

    return (
        <div>

            {/* text  */}
            <div className="md:w-[70%] w-full md:p-0 px-4 py-10 mt-16 mx-auto gap-4 flex flex-col justify-center items-center">
                <h1 className="text-[#2C2C2C] md:text-5xl text-3xl font-extrabold text-center">
                    Your Personal Information
                </h1>
                <p className="text-[#3E3E3E] md:w-[70%] text-center w-full">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>

                <div className="w-full rounded-3xl shadow-xl md:p-10 p-2 flex flex-col-reverse gap-10 md:flex-row">
                    {/* data */}
                    <div className="flex flex-col gap-4 md:w-[70%] w-full">


                        {/* last name */}
                        <div class='relative border rounded-md border-black px-2 py-4 w-full'>
                            <input
                                type='text'
                                name='name'
                                value={formData.name}
                                disabled
                                // onChange={handleInputChange}
                                class='w-full p-0 border-0 text-base font-normal disabled:bg-white focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                            />
                            <p class='absolute -top-3 left-5 bg-white px-2'>Full Name</p>
                        </div>

                        {/* email */}
                        <div class='relative border rounded-md border-black px-2 py-4 w-full'>
                            <input
                                type='text'
                                name='email'
                                value={formData.email}
                                disabled
                                // onChange={handleInputChange}
                                class='w-full p-0 border-0 text-base font-normal disabled:bg-white focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                            />
                            <p class='absolute -top-3 left-5 bg-white px-2'>Email</p>
                        </div>

                        {/*  mobile number */}
                        <div class='relative border rounded-md border-black px-2 py-4 w-full'>
                            <input
                                type='text'
                                name='mobileNumber'
                                value={formData.phoneNumber}
                                disabled
                                // onChange={handleInputChange}
                                class='w-full p-0 border-0 text-base font-normal disabled:bg-white  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                            />
                            <p class='absolute -top-3 left-5 bg-white px-2'>Mobile Number</p>
                        </div>

                        {/* address */}
                        <div class='relative border rounded-md border-black px-2 py-4 w-full'>
                            <input
                                type='text'
                                name='address'
                                value={formData.address}
                                disabled
                                // onChange={handleInputChange}
                                class='w-full p-0 border-0 text-base font-normal disabled:bg-white  focus:outline-none focus:ring-0 focus:border-0 focus:bg-white'
                            />
                            <p class='absolute -top-3 left-5 bg-white px-2'>Address</p>
                        </div>
                    </div>

                    <div className="w-full  md:w-[30%]">
                        {/* image */}
                        <div>

                            <div
                                className={
                                    selectedImage
                                        ? "relative h-40 w-40 mx-auto "
                                        : "relative h-40 w-40 mx-auto   rounded-[3rem]"
                                }
                            >
                                {selectedImage ? (
                                    <>
                                        {" "}
                                        <img
                                            src={selectedImage}
                                            alt="Selected"
                                            width={160}
                                            height={160}
                                            className="object-cover w-40 h-40 rounded-[3rem] shadow-md"
                                        />

                                    </>
                                ) : (
                                    <>
                                        {
                                            formData.profileImage ?
                                                <>
                                                    {" "}
                                                    <img
                                                        src={formData.profileImage}
                                                        alt="Selected"
                                                        width={160}
                                                        height={160}
                                                        className="object-cover w-40 h-40 rounded-[3rem] shadow-md"
                                                    />

                                                </> :
                                                <div className="flex items-center justify-center w-full h-full bg-[#E5ECF6] rounded-[3rem] p-3 shadow-md">
                                                    <img
                                                        src={userLogo}
                                                        alt="Selected"
                                                        width={160}
                                                        height={160}
                                                        className="object-cover w-30 h-30  object-center"
                                                    />
                                                </div>
                                        }
                                    </>
                                )}
                            </div>
                            <input
                                type="file"
                                id="image"
                                ref={fileInputRef}
                                accept="image/*"
                                // disabled={!isUpdateButtonVisible}
                                className="hidden"
                                onChange={handleImageChange}
                            />
                        </div>


                        <button onClick={handleImageUploadClick} className="border border-black text-sm font-medium text-customGreen w-full rounded-md py-3 mt-5">Add Image</button>

                    </div>


                </div>

            </div>


            {/* bottom dropdown */}
            <div>
                <div className="h-[19rem] mt-20 relative">
                    <img
                        loading="lazy"
                        className="h-[19rem] w-full  object-cover object-no-repeat object-center"
                        src={require("../../images/place_pic_1.png")}
                        alt="Terms and conditions"
                    />
                    <div className="w-[100%] top-0 left-0 absolute h-[19rem] bg-black bg-opacity-70 flex justify-center items-center">
                        <div className="inset-0 flex flex-col items-center justify-center text-white ">
                            <h1 className="text-2xl font-bold text-center text-white">
                                Join Our Community
                            </h1>
                            <div className="text-sm font-normal text-center md:w-[60%] w-full mt-6 mb-5">
                                <p className="mb-1">
                                    We invite you to become a part of our growing community of
                                    like-minded individuals who believe in the power of yoga to
                                    transform lives. Together, we strive to cultivate mindfulness,
                                    wellness, and joy in every moment.
                                </p>
                                <p>
                                    Thank you for considering us as your partner on this
                                    transformative journey. We look forward to welcoming you to
                                    our retreats and sharing the path to a more balanced and
                                    blissful life.
                                </p>
                            </div>
                            {/* Download Button */}
                            <div className="items-center hidden lg:flex ms-4">
                                <button

                                    className=" cursor-pointer px-14 py-2 font-normal text-white bg-[#8DC67B] rounded-md  hover:text-white gradient-bg"
                                >
                                    Get Started
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}
export default ProfilePage;
