// CategoriesContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader } from "../apis/header";

const CategoriesContext = createContext();

export const CategoriesContextProvider = ({ children }) => {
  const [allCategories, setAllCategories] = useState(null);

  const getCategories = async (token) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", "/api/v1/categories", {}, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setAllCategories(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const addCategory = async (token, categoryData) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("post", "/api/v1/category", categoryData, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setAllCategories([...allCategories, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const deleteCategory = async (token, categoryId) => {
    try {
      // Implement the logic to delete a category using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const updateCategory = async (token, categoryId, categoryData) => {
    try {
      // Implement the logic to update a category using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <CategoriesContext.Provider
      value={{
        allCategories,
        getCategories,
        addCategory,
        deleteCategory,
        updateCategory,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategoriesContext = () => {
  const categoriesContextValue = useContext(CategoriesContext);
  return categoriesContextValue;
};
