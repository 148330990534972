import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDtwwIj7My4S9OlI1rtOney_zsowfwa0Aw",
  authDomain: "sunkissyogi-c40a8.firebaseapp.com",
  projectId: "sunkissyogi-c40a8",
  storageBucket: "sunkissyogi-c40a8.appspot.com",
  messagingSenderId: "898704305141",
  appId: "1:898704305141:web:3df77846af89bfab488ebc",
  measurementId: "G-TJGQY14T5Y",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the authentication instance
export const auth = getAuth(app);

// Create a GoogleAuthProvider instance
const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

// Function to sign in with Google
export const signInWithGoogle = () => signInWithPopup(auth, provider);

export default app;
