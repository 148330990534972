import { useEffect, useState } from "react";
import Banner from "../../components/banner";
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { useContactSupportContext } from "../../contexts/ContactSupportContextProvider";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    context: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const {addAllQueries} = useContactSupportContext()

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = () => {
    setFormData({
      ...formData,
      agreeTerms: !formData.agreeTerms,
    });
  };

  


  const handleSignUp = async (e) => {
    try {
      e.preventDefault();
      setError(""); // Resetting previous errors
      setIsLoading(true);

    

      const requiredFields = ['name', 'email', 'context', 'message',];
      const emptyFields = requiredFields.filter(field => !formData[field]);

      if (emptyFields.length > 0) {
        setError(`Please fill in all required fields: ${emptyFields.join(', ')}`);
        setIsLoading(false);
        return;
      }

     
      const response = await addAllQueries(JSON.stringify({
        name: formData.name,
        email:  formData.email,
        context:  formData.context,
        message:  formData.message,
      }));
      console.log(response)
      if (!response) {
        setError("Failed to sign up. Please try again.");
        setIsLoading(false);
        return;
      }
      if (response && response["success"] == false) {
        setError(response["error"].message + ": " + response["error"].data[0]);
        setIsLoading(false);
      
        return;
      }
      else{
        setSuccess("Congratulation! Your query submitted successfully." );
        setIsLoading(false);
        setFormData({
          name: "",
          email: "",
          context: "",
          message: "",
        })
        return;
      }
      
      

      // Possibly close the modal or redirect upon successful login
    } catch (error) {
      setError("Failed to sign up. Please try again."); // Update error state
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let clearMessagesTimeout;
    if (error || success) {
      clearMessagesTimeout = setTimeout(() => {
        setError("");
        setSuccess("");
      }, 2200); // Clear messages after 17 seconds
    }

    return () => {
      clearTimeout(clearMessagesTimeout); // Clear timeout on unmount or state change
    };
  }, [error, success]);

  const openWhatsApp = () => {
    // Replace '1234567890' with the desired phone number in international format
    window.open('https://wa.me/+923329162468', '_blank');
  };
  return (
    <section>
      <Banner
        image={require("../../images/terms_conditions_bg.png")}
        title="Contact Us"
      />
      <div className="md:w-[60%] w-full md:p-0 px-4 mt-16 mx-auto">
        <h1 className="text-[#2C2C2C] md:text-5xl text-3xl font-extrabold text-center">
          Contact Us
        </h1>
        <div className="flex flex-col gap-5 mt-10 md:flex-row md:mt-20">
          {/* part 1 form */}
          <div className="md:w-[50%] w-full">
            <h3 className="text-lg font-bold">We'd love to hear from you</h3>
            <p className="text-[#3E3E3E]">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="flex flex-col mt-4 gap-5 text-[#3E3E3E]">
              {/* Location */}
              <div className="flex items-center gap-3">
                <FaMapMarkerAlt />
                <span>Your Location</span>
              </div>
              {/* Phone */}
              <div className="flex items-center gap-3">
                <FaPhone />
                <span>Your Phone Number</span>
              </div>
              {/* Email */}
              <div className="flex items-center gap-3">
                <FaEnvelope />
                <span>Your Email Address</span>
              </div>
            </div>
          </div>
          {/* part 2 */}
          <div className="md:w-[50%] w-full">
            <div className="relative mb-4">
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-3 border rounded outline-none border-C2D1D9"
              />
            </div>

            <div className="relative mb-4">
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-3 border rounded outline-none border-C2D1D9"
              />
            </div>

            <div className="relative mb-4">
              <select
                name="context"
                value={formData.context}
                onChange={handleInputChange}
                className="w-full p-3 border rounded outline-none border-C2D1D9"
              >
                <option value="" disabled selected>
                  Select Context
                </option>

                <option value="I am looking for a Yoga Retreat" >I am looking for a Yoga Retreat</option>
                <option value="I have a question about my inquiry" >I have a question about my inquiry</option>
                <option value="I have a question about my booking" >I have a question about my booking</option>
                <option value="I want to cancel my booking" >I want to cancel my booking</option>
                <option value="I need help with my payment" >I need help with my payment</option>
                <option value="I am at the Yoga Retreat" >I am at the Yoga Retreat</option>
                <option value="I have completed my holiday">I have completed my holiday</option>
                <option value="I have a question about my account">I have a question about my account</option>
                <option value="Other">Other</option>

              </select>
            </div>

            <div className="relative mb-2">
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Your Message"
                rows={4}
                className="w-full p-3 border rounded outline-none border-C2D1D9"
              />
            </div>

            {error && <p className="text-red-500 mb-2 text-sm pt-3">{error}</p>}
            {success && <p className="text-green-500 mb-2 text-sm pt-3">{success}</p>}

            <button
              onClick={(e)=>handleSignUp(e)}
              className="bg-[#8DC67B] text-white py-3 px-6 rounded-lg w-full"
            >
             {isLoading? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
      {/* WhatsApp Floating Button */}
      <div className="fixed bottom-8 right-8">
        <button onClick={openWhatsApp} className="px-4 py-4 text-white bg-green-500 rounded-full shadow-md">
          <FaWhatsapp size={24} />
        </button>
      </div>
    </section>
  );
};

export default Contact;
