import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const Success = () => {
  const location = useLocation();
  const [bookingData, setBookingData] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const eventId = localStorage.getItem("eventId");
  const planId = localStorage.getItem("planId");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get("session_id");
    if (sessionId) {
      console.log(
        "Payment successful! Session ID:",
        sessionId,
        eventId,
        planId
      );

      const sendSessionId = async () => {
        const token = JSON.parse(localStorage.getItem("token")); 
        try {
          const response = await axios.post(
            "https://api.sunkissyogi.com/api/v1/success",
            {
              session_id: sessionId,
              event_id: eventId,
              plan_id: planId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", 
              },
            }
          );

          if (response.data.success) {
            setBookingData(response.data.data.booking_data || {});
            setPaymentDetails(response.data.data.payment_details || {});
            setError(false);
          } else {
            setError(true);
          }
        } catch (error) {
          console.error("Error sending data to API:", error);
          setError(true);
        } finally {
          setLoading(false);
        }
      };

      sendSessionId();
    } else {
      console.error("Session ID not found in URL");
      setLoading(false);
      setError(true);
    }
  }, [location.search, eventId, planId]);

  if (loading) {
    return <div className="text-center mt-40">Loading...</div>;
  }

  if (error) {
    return (
      <div className="text-center mt-40 text-red-700">
        Payment failed. Please try again.
      </div>
    );
  }

  const formattedDate = paymentDetails?.created_at
    ? new Date(paymentDetails.created_at).toLocaleDateString()
    : "N/A";

  return (
    <>
      <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-auto">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-circle-check"
              width="100"
              height="100"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#00b341"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
          </span>
        </div>
        <div className="p-5 text-center">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Booking Details
          </h5>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            Booking ID: {bookingData?.id || "N/A"}
          </p>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            Customer Name: {paymentDetails?.customer_name || "N/A"}
          </p>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            Amount: ${paymentDetails?.amount || "N/A"}
          </p>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            Details: {bookingData?.booking_details || "N/A"}
          </p>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            Date: {formattedDate}
          </p>
        </div>
      </div>

      <div>
        <h1 className="text-2xl font-bold text-center text-green-700 mt-10">
          Payment was successful!
        </h1>
      </div>
    </>
  );
};

export default Success;
