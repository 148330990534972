import React from "react";
import Slider from "react-slick";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function WhyPeopleLoveFrom() {
  return (
    <div>
      <div className="md:w-[70%] w-full md:p-0 px-4 my-16 mx-auto">
        <h1 className="text-[#2C2C2C] md:text-5xl text-3xl font-extrabold text-center mt-8">
          Why people love Form
        </h1>
        
      </div>
      <div className="md:w-[70%] w-full md:p-0 px-4 my-16 mx-auto md:overflow-x-visible overflow-x-hidden">
          <ClientSlider />
        </div>
    </div>
  );
}

export default WhyPeopleLoveFrom;

function ClientSlider() {
  const NextArrow = ({ onClick }) => (
    <div
      className="arrow next md:-right-20 -right-7 top-1/2 transform -translate-y-1/2"
      onClick={onClick}
    >
      <FiChevronRight className="text-3xl" />
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div
      className="arrow prev md:-left-20 -left-7 top-1/2 transform -translate-y-1/2 "
      onClick={onClick}
    >
      <FiChevronLeft className="text-3xl" />
    </div>
  );

  const Dots = ({ dots }) => {
    return (
      <div className="flex justify-center mt-4">
        {dots.map((dot, index) => (
          <div
            key={index}
            className={`dot${
              dot.props.className.includes("slick-active") ? " active" : ""
            }`}
          ></div>
        ))}
      </div>
    );
  };
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    aautoplay: true, // Enable auto-scrolling
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => <Dots dots={dots} />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      {/* card 1 */}
      <div className="md:px-5 px-2 py-5  md:w-[40%] w-[90%]">
        <div className="rounded-md shadow-lg px-5 pb-5  md:px-8 md:pb-8 md:pt-16 pt-10 text-[#505D68]">
          {/* profile section */}
          <div className=" flex gap-5 items-center">
            <img
              loading="lazy"
              className="rounded-full w-[3.5rem] h-[3.5rem] object-contain object-center "
              src={require("../../images/place_pic_1.png")}
              alt="place"
            />

            <div>
              <p className="font-bold">Sean Farmer</p>
              <p className="opacity-70">Job Title</p>
            </div>
          </div>
          {/* description */}
          <div className="mt-5 md:w-[85%] w-full line-clamp-4 font-bold">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliquat enim
              ad minim veniam.
            </p>
          </div>
        </div>
      </div>
      {/* card 1 */}
      <div className="md:px-5 px-2 py-5  md:w-[40%] w-[90%]">
        <div className="rounded-md shadow-lg px-5 pb-5  md:px-8 md:pb-8 md:pt-16 pt-10 text-[#505D68]">
          {/* profile section */}
          <div className=" flex gap-5 items-center">
            <img
              loading="lazy"
              className="rounded-full w-[3.5rem] h-[3.5rem] "
              src={require("../../images/place_pic_1.png")}
              alt="place"
            />

            <div>
              <p className="font-bold">Sean Farmer</p>
              <p className="opacity-70">Job Title</p>
            </div>
          </div>
          {/* description */}
          <div className="mt-5 md:w-[85%] w-full line-clamp-4 font-bold">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliquat enim
              ad minim veniam.
            </p>
          </div>
        </div>
      </div>
      {/* card 1 */}
      <div className="md:px-5 px-2 py-5  md:w-[40%] w-[90%] ">
        <div className="rounded-md shadow-lg px-5 pb-5  md:px-8 md:pb-8 md:pt-16 pt-10 text-[#505D68]">
          {/* profile section */}
          <div className=" flex gap-5 items-center">
            <img
              loading="lazy"
              className="rounded-full w-[3.5rem] h-[3.5rem] "
              src={require("../../images/place_pic_1.png")}
              alt="place"
            />

            <div>
              <p className="font-bold">Sean Farmer</p>
              <p className="opacity-70">Job Title</p>
            </div>
          </div>
          {/* description */}
          <div className="mt-5 md:w-[85%] w-full line-clamp-4 font-bold">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliquat enim
              ad minim veniam.
            </p>
          </div>
        </div>
      </div>
      {/* card 1 */}
      <div className="md:px-5 px-2 py-5  md:w-[40%] w-[90%]">
        <div className="rounded-md shadow-lg px-5 pb-5  md:px-8 md:pb-8 md:pt-16 pt-10 text-[#505D68]">
          {/* profile section */}
          <div className=" flex gap-5 items-center">
            <img
              loading="lazy"
              className="rounded-full w-[3.5rem] h-[3.5rem] "
              src={require("../../images/place_pic_1.png")}
              alt="place"
            />

            <div>
              <p className="font-bold">Sean Farmer</p>
              <p className="opacity-70">Job Title</p>
            </div>
          </div>
          {/* description */}
          <div className="mt-5 md:w-[85%] w-full line-clamp-4 font-bold">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliquat enim
              ad minim veniam.
            </p>
          </div>
        </div>
      </div>
      {/* card 1 */}
      <div className="md:px-5 px-2 py-5  md:w-[40%] w-[90%]">
        <div className="rounded-md shadow-lg px-5 pb-5  md:px-8 md:pb-8 md:pt-16 pt-10 text-[#505D68]">
          {/* profile section */}
          <div className=" flex gap-5 items-center">
            <img
              loading="lazy"
              className="rounded-full w-[3.5rem] h-[3.5rem] "
              src={require("../../images/place_pic_1.png")}
              alt="place"
            />

            <div>
              <p className="font-bold">Sean Farmer</p>
              <p className="opacity-70">Job Title</p>
            </div>
          </div>
          {/* description */}
          <div className="mt-5 md:w-[85%] w-full line-clamp-4 font-bold">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliquat enim
              ad minim veniam.
            </p>
          </div>
        </div>
      </div>
    </Slider>
  );
}
