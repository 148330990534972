// SignInModal.js
import { signInWithGoogle } from '../../firebase';
import React, { useState } from "react";
import GoogleLogo from '../../images/google_logo.svg';
import { Link } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const { login,signupwithgooogle } = useAuthContext();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleCheckboxChange = () => {
        setAgreeTerms(!agreeTerms);
    };

    const handleSubmit = async () => {
        setError(""); // Resetting previous errors
        setIsLoading(true);

        // Perform form validation
        if (!email || !password || !agreeTerms) {
            setError("Please provide both email and password and check terms and conditions.");
            setIsLoading(false);
            return;
        }

        try {
            // Simulating API call or any asynchronous operation
            // Replace this with your actual API call
            const response = await login({ email, password });
            console.log(response)
            if (response && response["success"] == false) {
                setError(response["error"].message + ": " + response["error"].data[0]);
                setIsLoading(false);
                return;
            }
            if (!response) {
                setError("Failed to sign in. Please try again.");
                setIsLoading(false);
                return;
            }
            setIsLoading(false);
            navigate('/');

            // Possibly close the modal or redirect upon successful login
        } catch (error) {
            setError("Failed to sign in. Please try again."); // Update error state
            setIsLoading(false);
        }
    };

    const handleSignUpWithGoogle = async (e) => {
        try {
            e.preventDefault();
            setError(""); // Resetting previous errors
            // setIsLoading(true);

            const googleResponse = await signInWithGoogle();
            console.log(googleResponse)
            const response = await signupwithgooogle(JSON.stringify({
                "auth_type":"google",
                name: googleResponse.user.displayName ? googleResponse.user.displayName: "",
                phone: googleResponse.user.phoneNumber ? googleResponse.user.phoneNumber: "",
                address: googleResponse.user.photoURL?googleResponse.user.photoURL: "",
                email: googleResponse.user.email ? googleResponse.user.email: "",
                uid: googleResponse.user.uid ? googleResponse.user.uid: "",
                avater: googleResponse.user.photoURL ? googleResponse.user.photoURL: "",
            }));
            console.log(response)
            if (response && response["success"] == false) {
                setError(response["error"].message + ": " + response["error"].data[0]);
                setIsLoading(false);
                return;
            }
            if (!response) {
                setError("Failed to sign up. Please try again.");
                setIsLoading(false);
                return;
            }
            setIsLoading(false);
            navigate('/');

            // Possibly close the modal or redirect upon successful login
        } catch (error) {
            setError("Failed to sign up. Please try again."); // Update error state
            setIsLoading(false);
        }
    }

    return (
        <section className="w-full py-10">
            <div className="text-black flex flex-col md:w-[23rem] mx-auto">
                <h1 className="mb-3 text-2xl font-black">Sign In</h1>
                <h3 className="text-xl text-customGreen">Welcome!</h3>
                <p className="mb-3 text-sm text-customGray">
                    Please sign in to join our retreat plans.
                </p>

                <div className="mb-3">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        className="w-full px-3 py-2 border rounded-md"
                    />
                </div>
                <div className="mb-1">
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                        className="w-full px-3 py-2 border rounded-md"
                    />
                </div>
                <p className="text-sm text-right text-customGreen">Forgot Password <span className="px-1 text-white rounded-full bg-customGreen">?</span></p>
                <div className="flex items-start justify-between mt-4">
                    <label className="flex items-center text-xs">
                        <input type="checkbox" className="mr-2" checked={agreeTerms}
                            onChange={handleCheckboxChange} />
                        <p>I agree with all your
                            <Link to={"/privacy-policy"}><span className="text-customGreen">{" "} Terms & Conditions {" "}</span></Link> of
                            clarity</p>
                    </label>
                </div>
                {error && <p className="text-red-500 text-sm pt-3">{error}</p>}
                <div className="flex flex-col w-full gap-3 mt-4">
                    <button
                        // type="submit"
                        onClick={handleSubmit}
                        className="bg-[#8DC67B] text-white text-sm py-2 px-4 rounded-md mr-2 w-full"
                    >
                        {isLoading ? 'Signing In...' : 'Sign In'}
                    </button>
                    <button onClick={handleSignUpWithGoogle} className="flex items-center justify-center w-full gap-3 px-4 py-2 text-sm text-black rounded-md bg-customGray bg-opacity-30">
                        <img loading="lazy" src={GoogleLogo} alt="Google" className="w-5"></img> <p>Sign Up With Google</p>
                    </button>
                </div>

                <p className="mt-5 text-xs text-center text-customGray">
                    Don't have any account!{" "}
                    <Link to={"/signup"}>
                        <span className="text-[#8DC67B] cursor-pointer" >
                            Sign Up
                        </span>
                    </Link>
                </p>
            </div>
        </section>
    );
};

export default SignIn;
