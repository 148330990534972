import React, { useState, useEffect, useRef } from "react";
import { FiSearch, FiChevronUp, FiChevronDown } from "react-icons/fi";
import ClickAwayListener from "react-click-away-listener";
import { useLocationContext } from "../contexts/LocationContextProvider";
import { useEventContext } from "../contexts/EventContextProvider";


const SearchableDropdown = () => {
  const [query, setQuery] = useState("");
  const dropdownButtonRef = useRef(null);
  const [options, setOptions] = useState(["All"]);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { allLocations,
    getLocations,
  } = useLocationContext();
  const { getEventsByLocation, getEvents } = useEventContext();
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);



  const updateOptions = () => {
    options && setFilteredOptions(options);
  }

  const getAllLocations = async () => {
    await getLocations();
  }

  const setLocationsOptions = async () => {
    allLocations && setOptions(["All", ...allLocations.map(item => item.name)])
  }


  useEffect(() => {
    getAllLocations();
  }, [])

  useEffect(() => {
    setLocationsOptions();
  }, [allLocations])

  useEffect(() => {
    updateOptions();
  }, [options])



  // useEffect(() => {
  //   if (allLocations) {
  //     setFilteredOptions([
  //       ...options.filter((option) =>
  //         option.toLowerCase().includes(query.toLowerCase()),

  //       )],
  //       ...options

  //     );
  //     // setIsDropdownVisible(true);
  //   }
  // }, [query]);



  const handleInputChange = (e) => {
    setQuery(e.target.value);
    if (allLocations) {
      setFilteredOptions([
        ...options.filter((option) =>
          option.toLowerCase().includes(e.target.value.toLowerCase()),

        )],


      );

    }
    setIsDropdownVisible(e.target.value.length > 0);
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(isDropdownVisible => !isDropdownVisible);
  };

  const handleDropdownClickAway = () => {
    setIsDropdownVisible(false);
  };

  const handleGo = async () => {
    console.log("button go")
    setLoading(true)
    if (query !== "All") {
      const data = JSON.stringify({ "location": query })
      await getEventsByLocation(data);
    } else {
      await getEvents();
    }
    setLoading(false)
  }

  const handleOptionClick = (option) => {
    setQuery(option);
    setLocationsOptions();
    setIsDropdownVisible(false);
  }

  return (
    <div className="z-10 mt-5 flex md:w-full gap-5">
      <div className="relative inline-block text-left w-[80%]" ref={dropdownRef}>
        <div className="relative">
          <input
            type="text"
            className="block text-black outline-none border-none w-full pl-3 pr-10 py-3 text-base border border-gray-300 rounded-md focus:ring focus:ring-indigo-300 focus:border-indigo-300"
            placeholder="Search"
            // onMouseDownCapture={()=>setIsDropdownVisible(true)}
            value={query}
            onChange={handleInputChange}
          />
          <div
            className="absolute inset-y-0 right-0 flex items-center px-3 cursor-pointer"
            onClick={toggleDropdown}
            ref={dropdownButtonRef}
          >
            {isDropdownVisible ? (
              <FiChevronUp className="text-black" />
            ) : (
              <FiChevronDown className="text-black" />
            )}
          </div>
        </div>

        {isDropdownVisible && filteredOptions.length > 0 ? (
          <ClickAwayListener onClickAway={handleDropdownClickAway}><div className="mt-2 max-h-[10rem] overflow-y-scroll w-full absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg">
            {filteredOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option)}
                className={`py-2 px-4 text-black cursor-pointer hover:bg-gray-100 ${option === query ? "bg-green-300 font-bold" : ""}`}
              >
                {option}
              </div>
            ))}
          </div>
          </ClickAwayListener>
        ) :
          isDropdownVisible && <ClickAwayListener onClickAway={handleDropdownClickAway}><div className="mt-2 max-h-[10rem] overflow-y-scroll w-full absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg">
            <div

              className="py-2 px-4 text-black cursor-pointer hover:bg-gray-100"
            >
              No Location Found.
            </div>
          </div>
          </ClickAwayListener>

        }
      </div>
      <button onClick={handleGo} className="bg-[#8DC67B] text-black rounded-md w-[20%] z-10">{loading ? "Loading..." : "Go!"}</button>
    </div>
  );
};

export default SearchableDropdown;
