import React from "react";
import { FaCalendar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useEventContext } from "../../contexts/EventContextProvider";
import { baseUrl } from "../../apis/base_url";

function OurPlans() {
  const { allEvents } = useEventContext();
  
  return (
    <div className="md:w-[60%] w-full md:p-0 px-4 my-16 mx-auto">
      <h1 className="text-[#2C2C2C] md:text-5xl text-3xl font-extrabold text-center">
        Our Retreat Plans
      </h1>
      <h3 className="text-[#505D68] md:text-2xl text-xl font-semibold text-center">
        Discover Your Inner Peacer
      </h3>

      <div className="mt-5 flex flex-col gap-5">
        {
          allEvents && allEvents["events"].map((item, index) => {
            return <RetreatPlanCard key={index} data={item} />
          })
        }
      </div>

      <div className="text-center text-red-500">{allEvents&&allEvents["events"].length == 0 && "Oops! Events not found in this location."}</div>

    </div>
  );
}

export default OurPlans;

function RetreatPlanCard({ key, data }) {
  return (
    <div key={key} className="eventsCard shadow-lg flex gap-4 p-4 rounded-md text-[#505D68] md:flex-row flex-col  cursor-pointer ">
      {/* image */}
      <div className="md:w-[25%] w-full">
        <Link to={`/retreat-plans/${data.id}`}>
          <img
            loading="lazy"
            className="w-full object-cover h-[13.4rem]  object-center rounded-md"
            src={baseUrl+"/"+data.card_image.path+"/"+data.card_image.name}
            alt="place"
          />
        </Link>
      </div>
      {/* text */}
      <div className="md:w-[75%] w-full flex flex-col justify-between">
        <div className="flex items-center justify-between w-full">
          <h4 className="font-normal text-base">{data.category.name}</h4>
          <p>
            No. of Persons: <span className="font-extrabold">{data.total_group_members}</span>/group
          </p>
        </div>
        <h3 className="text-xl font-bold md:w-[70%] w-full text-black">
          <Link to={`/retreat-plans/${data.id}`}>{data.title}</Link>
        </h3>
        <p>
          <span className="font-extrabold">Duration: </span> {data.duration} Days,
          <span className="font-extrabold"> Description:</span> {data.description}
        </p>
        <div className="flex items-center justify-between w-full">
          <p className="flex gap-3">
            <span>
              <FaCalendar size={20} />
            </span>
            May-Aug
          </p>
          <h3 className="text-2xl font-bold text-black">{data.price}$</h3>
        </div>
      </div>
    </div>
  );
}
