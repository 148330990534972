// CoordinatesContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";

const CoordinatesContext = createContext();

export const CoordinatesContextProvider = ({ children }) => {
  const [allCoordinates, setAllCoordinates] = useState(null);

  const getCoordinates = async (token) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("get", "/api/v1/get/coordinators", {}, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setAllCoordinates(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const addCoordinate = async (token, coordinateData) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/set/coordinator", coordinateData, headers);
      if (response && response["success"] === true) {
        console.log(response["data"]);
        setAllCoordinates([...allCoordinates, response["data"]]);
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const deleteCoordinate = async (token, coordinateId) => {
    try {
      // Implement the logic to delete a coordinate using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const updateCoordinate = async (token, coordinateId, coordinateData) => {
    try {
      // Implement the logic to update a coordinate using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <CoordinatesContext.Provider
      value={{
        allCoordinates,
        getCoordinates,
        addCoordinate,
        deleteCoordinate,
        updateCoordinate,
      }}
    >
      {children}
    </CoordinatesContext.Provider>
  );
};

export const useCoordinatesContext = () => {
  const coordinatesContextValue = useContext(CoordinatesContext);
  return coordinatesContextValue;
};
