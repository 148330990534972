import React, { useEffect, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useEventContext } from "../../contexts/EventContextProvider";
import { baseUrl } from "../../apis/base_url";
import { ColorRing } from 'react-loader-spinner'
import { useBlogsContext } from "../../contexts/BlogsContextProvider";


function AllBlogs() {
    const [loading, setLoading] = useState(false);

    const { allBlogs,
        getBlogs, } = useBlogsContext();

    const fetchAllBlogs = async () => {
        setLoading(true);
        await getBlogs();
        setLoading(false);
    }

    useEffect(() => {
        fetchAllBlogs();
    }, []);

    return (
        <>{
            !loading ?
                allBlogs && <div className="md:w-[90%] w-full md:p-0 px-4 my-16 mx-auto">
                    <h1 className="text-[#2C2C2C] md:text-5xl text-3xl font-extrabold text-center">
                        Blogs
                    </h1>
                    <h3 className="text-[#505D68] md:text-2xl text-xl font-semibold text-center">
                        Discover Your Inner Peacer
                    </h3>

                    <div className="flex flex-row gap-5 mt-5 flex-wrap items-center justify-center">
                        {
                            allBlogs && allBlogs.map((event, id) => {
                                return <div key={id} className="md:px-5 px-2 py-5  md:w-[27rem] w-[90%]">
                                    <div className="rounded-md shadow-lg text-[#505D68]">
                                        <div className=" relative">
                                            <img
                                                loading="lazy"
                                                className="rounded-md w-full h-[16rem] object-fill object-center"
                                                src={baseUrl + "/" + event.image.path + "/" + event.image.name}
                                                alt="place"
                                            />
                                            <div className="absolute top-0 left-0 w-full h-full gradient-background  rounded-md z-10"></div>
                                            <p className="font-bold z-20 absolute text-white bottom-5 left-5 line-clamp-2 ">
                                                {event.title}
                                            </p>
                                        </div>
                                        <div className="md:p-5 p-3">
                                            {/* description */}
                                            <div className="mb-5  w-full line-clamp-2 ">
                                                <p>
                                                    {event.introduction}
                                                </p>
                                            </div>

                                            <div className="flex items-center justify-between ">
                                                {/* profile section */}
                                                <div className=" flex gap-5 items-center">
                                                    <h1
                                                        className="rounded-full w-[2.5rem] h-[2.5rem] bg-black text-white font-semibold text-center text-3xl pt-1"

                                                    >{event?.user?.name[0]}</h1>

                                                    <div>
                                                        <p className="opacity-70 text-sm">{event?.user?.name}</p>
                                                        <p className="opacity-70 text-sm">{event?.posted_at}</p>
                                                    </div>
                                                </div>
                                                {/*   learn more*/}

                                                <Link to={"/blog/" + event.id} className="underline text-base cursor-pointer">
                                                    Learn More
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>

                    <div className="text-center text-red-500">{allBlogs && allBlogs.length == 0 && "Oops! Blogs not found."}</div>

                </div> : <div className="flex items-center justify-center w-full my-20 text-center"><ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["black"]}
                /></div>
        }</>
    );
}

export default AllBlogs;


