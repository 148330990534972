import React, { useEffect, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { IoMenu } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import SignUpModal from "../../components/sign_up_model";
import SignInModal from "../../components/sign_in_model";
import { BiSolidChevronDown } from "react-icons/bi";


import profileIcon from "../../images/profile_icon.svg";
import tripIcon from "../../images/trip_icon.svg";
import messagesIcon from "../../images/messages_icon.svg";
import settingIcon from "../../images/setting_icon.svg";
import { useAuthContext } from "../../contexts/AuthContextProvider";

const navItems = [
  { text: "Home", link: "/" },
  { text: "Retreats", link: "/retreat-plans" },
  { text: "Blogs", link: "/blogs" },
  { text: "About us", link: "/about-us" },
  { text: "Contact Us", link: "contact-us" }
];

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [profileDD, setProfileDD] = useState(false);
  const { checkUser, isLoggedIn, logout, userProfile } = useAuthContext();


  const navigate = useNavigate();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsSignUp(false);
    setIsSignIn(false);
  };


  const handleLogout = async () => {
    await logout();
    return navigate("/")
  }

  const Profile = () => {


    return (
      <>
        {isLoggedIn ? (
          <div className="relative ">
            <div
              className="flex items-center gap-2 cursor-pointer ms-5"
              onClick={() => {
                setProfileDD(!profileDD);
                setIsMenuOpen(false);
              }}
            >
              {
                (userProfile && userProfile.avater) ? <img
                  loading="lazy"
                  className="w-[3rem] h-[3rem] rounded-full object-center object-cover flex flex-shrink-0"
                  src={userProfile.avater}
                  alt="profile "
                ></img> : <img
                  loading="lazy"
                  className="w-[3rem] h-[3rem] rounded-full object-center object-cover flex flex-shrink-0"
                  src={require("../../images/profile_icon.jpg")}
                  alt="profile "
                ></img>
              }

              <BiSolidChevronDown size={25} />
            </div>
            {profileDD && (
              <div className="absolute top-12 right-0 rounded-xl bg-white shadow-lg  min-w-[13rem] md:min-w-[13rem] z-50">
                <div className="px-4 pt-4 border-b-2 border-black">
                  <Link to="/profile">
                    <div className="flex items-center justify-start gap-3 p-3 cursor-pointer">
                      <img loading="lazy" src={profileIcon} alt="Profile" size={25} />
                      <p className="font-bold">My Profile</p>
                    </div>
                  </Link>
                  <div className="flex items-center justify-start gap-3 p-3 cursor-pointer">
                    <img loading="lazy" src={tripIcon} alt="Profile" size={25} />

                    <p className="font-bold">My Trips</p>
                  </div>

                </div>
                <div
                  onClick={handleLogout}
                  className="flex items-center justify-center p-3 cursor-pointer"
                >
                  <p className="font-bold">Logout</p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {/* Download Button */}
            <div className="items-center ms-4">
              <Link to="/login">
                <button
                  // onClick={toggleModal}
                  className=" cursor-pointer px-10 py-2 font-normal text-white bg-[#8DC67B] rounded-md  hover:text-white gradient-bg"
                >
                  Login
                </button>
              </Link>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsMenuOpen(false);
        setProfileDD(false);
      }}
    >
      <nav
        className={`navbar top-0  bg-white text-black ${isMenuOpen && "open"
          } md:p-4 p-3 w-[100%] z-50`}
      >
        <div className="md:w-[80%] w-[100%] relative mx-auto flex flex-wrap items-center justify-between mt-7">
          {/* Logo */}
          <Link to="/">
            <div className="flex items-center flex-shrink-0 text-black ">
              <h1 className="text-2xl font-bold min-w-max md:text-3xl">
                Yoga Retreats
              </h1>
            </div>
          </Link>

          {/* Menu Button for medium and small screens */}
          <div className="block lg:hidden ">
            <div className="flex gap-3">
              <div className="flex items-center">
                {/* profile */}
                <Profile />
              </div>
              <button
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                  setProfileDD(false);
                }}
                className="flex items-center px-2 py-1 text-black border-2 border-black rounded"
              >
                {isMenuOpen ? (
                  <span className="p-0 m-0 text-4xl font-bold">&times;</span>
                ) : (
                  <IoMenu />
                )}
              </button>
            </div>
          </div>

          {/* Navbar Items for large screens */}

          <div
            className={`lg:flex bg-white rounded-lg top-20 z-30  lg:top-0 lg:relative absolute lg:items-center lg:w-auto w-full ${isMenuOpen ? "block " : "hidden"
              }`}
            id="menu"
          >
            <ul className="flex flex-col justify-center list-none lg:flex-grow lg:justify-normal lg:flex-row lg:ml-auto">
              {navItems.map((item, index) => (
                <li className="relative nav-item" key={index}>
                  <Link
                    to={item.link}
                    className={`px-4 py-2 flex items-center justify-center text-[#676767] font-normal text-base`}
                  >
                    {item.text}
                  </Link>
                </li>
              ))}
            </ul>

            {/* profile */}
            <div className="hidden lg:flex">{Profile()}</div>
          </div>
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 ">
            <ClickAwayListener onClickAway={toggleModal}>
              <div className="px-3 py-5 mx-4 my-10 bg-white rounded-lg shadow-lg modal-content h-fit md:px-7 md:py-10">
                {isSignUp && (
                  <SignUpModal
                    onClose={() => {
                      setIsSignUp(false);
                      setIsSignIn(true);
                    }}
                    onSubmit={() => {

                      setIsModalOpen(false);
                      setIsSignUp(false);
                      setIsSignIn(false);
                    }}
                  />
                )}

                {isSignIn && (
                  <SignInModal
                    onClose={() => {
                      setIsSignUp(true);
                      setIsSignIn(false);
                    }}
                    onSubmit={() => {

                      setIsModalOpen(false);
                      setIsSignUp(false);
                      setIsSignIn(false);
                    }}
                  />
                )}

                {!isSignIn && !isSignUp && (
                  <>
                    <h1 className="mb-4 text-2xl font-black text-center text-black">
                      Do you have an account?
                    </h1>
                    <div className="flex flex-col items-center justify-center gap-1 mt-3 mb-4">
                      <button
                        className="mx-2 px-24 py-2 bg-[#8DC67B] rounded-md text-white w-min"
                        onClick={() => {
                          setIsSignUp(false);
                          setIsSignIn(true);
                        }}
                      >
                        Login
                      </button>
                      <p className="text-xl font-bold">or</p>
                      <button
                        onClick={() => {
                          setIsSignUp(true);
                          setIsSignIn(false);
                        }}
                        className="cursor-pointer mx-2 px-12 py-2 border border-[#8DC67B] rounded-md text-[#8DC67B] bg-white w-max"
                      >
                        Create an Account
                      </button>
                    </div>
                  </>
                )}
              </div>
            </ClickAwayListener>
          </div>
        )}

        { }
      </nav>
    </ClickAwayListener>
  );
};

export default Navbar;
